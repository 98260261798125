@import "../../../../styles/global.scss";

.bgWhite {
  background: #ffffff !important;
}

.bgDarkBlue {
  background: $bg-dark-blue !important;
}

.bgSoftOrange {
  background: $bg-soft-orange !important;
  z-index: 2000;
}

.inMobile {
  display: none;
  @media (max-width: 820px) {
    display: block;
  }
}

.inDesktop {
  display: block;
  @media (max-width: 820px) {
    display: none;
  }
}

.formCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 820px) {
    margin-left: 10px;
    width: 100%;
  }
}

.title {
  font-size: 34px;
}

.paragraph {
  font-size: 14px;
  max-width: 700px;
}

.big {
  color: #ffa800;
  font-size: 48px;
}

.mid {
  color: #ffa800;
  font-size: 24px;
}

.btnCont {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: auto;
}

.superCont {
  padding: 20px 20px 0px 20px;
  @media (max-width: 820px) {
    padding: 10px;
  }
}

.fadeIn {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInLeft {
  -webkit-animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
