@import "../../../../styles/global.scss";

.bgOrange {
  background: black;
}

// CategoryCard
.card {
  margin-right: 10px;
  width: 135px;
  height: 185px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $cremeOpaque;
  border-radius: 12px;
  cursor: pointer;
  transition: all ease 0.3s !important;
  &:hover {
    transform: scale(1.05);
  }
  &:last-child {
    margin-right: 0px;
  }

  .cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .name {
    text-transform: capitalize;
    font-weight: bold;
    color: $grey;
    margin-top: 5px;
  }

  @media (max-width: 820px) {
    background: red;
    width: 100px;
    height: 100px;
  }

  @media (max-width: 500px) {
    background: blue;
    width: 50px;
    height: 50px;
  }
}

.active {
  background: #50cd89;
  .name {
    color: #ffffff;
  }
}

.icon {
  width: 100px;
  height: 50px;
  svg {
    width: 100%;
    height: 100px;
    path {
      fill: $grey;
    }
  }
}

.iconActive {
  width: 100px;
  height: 50px;
  svg {
    width: 100%;
    height: 100px;
    path {
      fill: $green;
    }
  }
}

// SubCategoryList
.list {
  display: flex;
  padding: 10px 20px;
  transition: all ease 0.3s;
  .item {
    color: $grey;
    padding: 10px 20px;
    border-right: 1px solid $grey;
    font-weight: 500;
    cursor: pointer;
    transition: all ease 0.3s !important;
    &:first-child {
      border-left: 1px solid $grey;
    }
  }

  .itemActive {
    background: rgba($green, 0.07);
    color: $greenIntense;
    padding: 10px 20px;
    border-right: 1px solid $grey;
    font-weight: 500;
    cursor: pointer;
    transition: all ease 0.3s !important;
    &:first-child {
      border-left: 1px solid $grey;
    }
  }
}

// Category List
.title {
  font-size: 34px;
}

.leftSide {
  width: 45%;
  padding: 10px;
}

.rightSide {
  width: 55%;
  padding: 10px;
}

.minisquare {
  width: 50%;
  padding-right: 10px;
  height: 78px;
  div {
    p {
      color: black;
    }
    div {
      font-size: 26px;
      font-weight: bold;
      background: silver;
      padding: 20px;
    }
  }
}

.orange {
  div {
    div {
      background: $bg-orange;
    }
  }
}

.green {
  div {
    div {
      background: $green;
    }
  }
}

.check {
  span {
    margin-left: 5px;
  }
}

// ProjectList
.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
  background: #ef5944;
  border-radius: 20px;
  padding: 3px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.fadeInFwd {
  -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
