$bg-orange: #ffa800;
$bg-soft-orange: #ffd7a3;
$bg-dark-blue: #1d193b;

$grey: #b5b5c3;

$creme: #d2c9c5;
$cremeOpaque: #d2c9c54d;

$green: #84dcac;
$greenIntense: #22c55e;


/* Animations */
.fadeIn {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
