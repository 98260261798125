.mainView {
  display: flex;
  justify-content: flex-start;
  background: #1d193b;
  height: 100vh;
  overflow: hidden;
  @media (max-width: 820px) {
    flex-direction: column;
    height: initial;
  }
}

.leftCont {
  display: flex;
  justify-content: center;
  width: 50%;
  background: white;
  padding: 50px 5%;
  overflow-y: auto;
  @media (max-width: 820px) {
    width: 100%;
    padding: 30px;
  }
}

.rightCont {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: white;
      font-size: 43px;
      padding-top: 5%;
      text-align: center;
    }
    h3 {
      color: white;
      font-size: 10px;
      padding-top: 5%;
      text-align: center;
    }
  }
  @media (max-width: 820px) {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.bottomSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .sub {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    .title {
      padding: 30px;
      max-width: 70%;
      text-align: center;
    }

    .cont {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 1024px;
      @media (max-width: 1024px) {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
      }
      .list {
        display: flex;
        @media (max-width: 1024px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.videoCard {
  margin-right: 40px;
  max-width: 290px;
  &:last-child {
    margin-right: 0px;
  }
  @media (max-width: 1024px) {
    align-items: center;
    margin-right: 0px;
    margin-bottom: 40px;
  }

  .image {
    border-radius: 12px;
  }

  .videoTitle {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .videoText {
    font-size: 15px;
    font-weight: medium;
  }
}
