@import "../../../../styles/global.scss";

.bgTigre {
  background-color: $bg-orange;
}

.btnEffect {
  &:hover {
    background: #ffffff1a;
  }
  &:focus {
    background: #ffffff1a;
  }
}
