.bot {
  display: flex;
  border: 0.663636px solid #000000;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 4px;
  align-items: center;

  div:first-child {
    flex: 1;
    & > p:first-child {
      margin: 0;
      font-weight: bold;
    }
    & > p:nth-child(2) {
      margin: 0;
      color: #8896ab;
      font-size: 10px;
      font-weight: bold;
    }
  }

  div:nth-child(2) {
    flex: 2;
    text-align: center;
    color: #556987;
    font-weight: 500;
  }

  div:nth-child(3) {
    flex: 1;
    text-align: right;
    button {
      padding: 10px 20px;
      border-radius: 5px;
      background-color: #22c55e;
      color: white;
      border: 0px solid white;
    }
  }
}

.cont {
  margin: auto;
  max-width: calc((135px * 7) + 60px);
}
